<script lang="ts" setup>
import type { CmsElementBuilderIo } from '~/types/models/cms/cmsElementTypes';
// @ts-ignore
import { fetchOneEntry, isPreviewing, Content } from '@builder.io/sdk-vue';

const props = defineProps<{
    content: CmsElementBuilderIo;
}>();

const config = useRuntimeConfig();
const { locale } = useI18n();
const { getConfigValue } = useCmsElementConfig(props.content);

const publicApiKey = String(config.public.builderIoPubKey);
const urlPath = computed(() => getConfigValue('urlPath'));

const { status, data: builderIoContent } = useLazyAsyncData('builder-data-' + urlPath.value, () =>
    fetchOneEntry({
        model: 'page',
        locale: locale.value,
        apiKey: publicApiKey,
        userAttributes: {
            urlPath: urlPath.value,
        },
    }),
);
const canShowContent = computed(() => !!builderIoContent.value || isPreviewing());

const { block } = useBlock();
const stopWatcher = watch(status, () => {
    if (status.value !== 'pending') {
        if (!canShowContent.value) {
            block.hidden = true;
            stopWatcher();
        }
    }
});
</script>

<template>
    <div class="cms-element-builder-io">
        <LazyHtmlFixer v-if="canShowContent">
            <Content
                model="page"
                :content="builderIoContent"
                :api-key="publicApiKey"
            />
        </LazyHtmlFixer>
        <div v-else-if="status !== 'pending'">Content not Found</div>
    </div>
</template>

<style lang="scss">
.cms-element-builder-io {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: inherit;
        font-weight: inherit;
    }
}
</style>
